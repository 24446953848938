import React, { createContext, useEffect, useState } from 'react';
import Head from 'next/head'
import { DefaultSeo, LocalBusinessJsonLd, CorporateContactJsonLd } from 'next-seo';
import '../Styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import Script from 'next/script';
import _JSXStyle from 'styled-jsx/style';
config.autoAddCss = false;
import { useRouter } from 'next/router';

export const PromotionContext = createContext();
const App = ({ Component, pageProps }) => {
    const [promoState, setPromoState] = useState(false)
    const [dateActiveState, setDateActiveState] = useState(null)
    const [detailsState, setDetailsState] = useState({ text: null, textForm: null, new_price: null })
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState([])
    const [triggers, setTriggers] = useState([])
    const [link, setLink] = useState([])
    const [tagsTriggers, setTagstriggers] = useState([])
    const router = useRouter()

    useEffect(() => {
        setTagstriggers([])
        const scripts = triggers.find((trigger) => trigger.page === router.pathname)
        scripts != undefined ? setTagstriggers(scripts.scripts) : []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname, triggers]);

    useEffect(() => {
        process.env.PIXELS != undefined && setTags(JSON.parse(process.env.PIXELS?.replaceAll("`", "")));
        process.env.EVENTS != undefined && setTriggers(JSON.parse(process.env.EVENTS?.replaceAll("`", "")));
        process.env.LINKS != undefined && setLink(JSON.parse(process.env.LINKS?.replaceAll("`", "")));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        promotionsApiRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    const promotionsApiRoute = () => {
        fetch("/api/edge/promotions").then((response) => {
            response.json().then((res) => {
                if (res.details?.pages?.includes("*") || res.details?.pages?.includes(router.pathname)) {
                    setPromoState(res?.promo)
                    setDateActiveState(res?.dateActive)
                    const details = {
                        ...res?.details,
                        "show_banner": res?.details?.show_banner != undefined ? res?.details?.show_banner : true
                    }
                    setDetailsState(details)
                } else{
                    setPromoState(false);
                    setDateActiveState(null)
                    setDetailsState({ text: null, textForm: null, new_price: null })
                }

            }).catch((err) => {
                console.log(err)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <DefaultSeo
                canonical='https://fixat.mx/'
                noindex={true}
                nofollow={true}
                openGraph={{
                    title: 'https://fixat.mx/',
                    site_name: 'Fixat'
                }}
                additionalLinkTags={[
                    {
                        rel: 'shortcut icon',
                        href: 'https://cdn.fixat.mx/assets/img/no-webp/favicon.ico',
                    },
                    {
                        rel: 'manifest',
                        href: '/manifest.json'
                    }
                ]}
            />
            <LocalBusinessJsonLd
                type="Organization"
                name="Fixat"
                url="https://fixat.mx/"
                logo="https://cdn.fixat.mx/assets/img/no-webp/logo-principal-fixat.png"
                address={{
                    streetAddress: 'Blvd. Adolfo López Mateos 163',
                    addressLocality: 'Benito Juárez',
                    addressRegion: 'CDMX',
                    postalCode: '03910',
                    addressCountry: 'MX',
                }}
                sameAs={[
                    'https://www.facebook.com/Fixat-228162854300046/?fref=ts',
                    'https://www.instagram.com/fixatoficial'
                ]}
            />
            <CorporateContactJsonLd
                url="https://fixat.mx/"
                logo="https://cdn.fixat.mx/assets/img/no-webp/logo-principal-fixat.png"
                contactPoint={[
                    {
                        telephone: '4461048431',
                        contactType: 'customer service',
                    }
                ]}
            />
            <Head>
                <noscript>
                    <_JSXStyle>{`
                        #__next {display:none;}
                    `}</_JSXStyle>
                    <div className="noscriptmsg">
                        You need to enable JavaScript to run this app.
                    </div>
                </noscript>
            </Head>
            {/*<Script src="/userBehaviour/userBehaviour.js" strategy="beforeInteractive"/>*/}
            {/* {env == 'production' ?
                <Script rel="preconnect" strategy="afterInteractive" id="GTM-p" dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-TDK4VV5');`}} />
                :
                <Script rel="preconnect" strategy="afterInteractive" id="GTM-s" dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-PBL4MBH');`}} />
            }
            {env === 'production' ?
                <Script rel="preconnect" strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-R277NRX7F2" />
                :
                <Script rel="preconnect" strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-LG30GQRXXN" />
            }
            {env === 'production' ?
                <Script rel="preconnect" strategy="afterInteractive" id="gtag-p" dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-R277NRX7F2');`
                }} />
                :
                <Script rel="preconnect" strategy="afterInteractive" id="gtag-s" dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-LG30GQRXXN');`
                }} />
            }
            {env !== 'production' &&
                <Script rel="preconnect" strategy="afterInteractive" defer src="https://kea-collective.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b401c7b3" />
            } */}
            {link.length != 0 && link.map((linkItem, index) => (<link rel={linkItem.rel} href={linkItem.src} key={index} />))}

            {tagsTriggers.length != 0 ?
                tagsTriggers.map((tagsT, index) => (
                    <Script id={index} strategy='afterInteractive' key={index}
                    >
                        {tagsT}
                    </Script>
                )) : <></>
            }
            {tags.length != 0 && tags.map((tag, index) =>
                <span key={index}>
                    {/* {tag?.name == 'viral-loops' ?
                        (<Script strategy='afterInteractive' src={tag.src} id={tag.id} type={tag.type} data-campaign-id={process.env.VIRAL_LOOPS_ID_CAMPAIGN}>
                            {tag?.function}
                        </Script>) : (<>
                            {tag.src != '' && <Script strategy='afterInteractive' id='' src={tag.src} />}
                            <Script strategy='afterInteractive' id={`id-${index}`}>
                                {tag?.function}
                            </Script>
                        </>)
                    } */}
                    <>
                        {tag.src != '' && <Script strategy='afterInteractive' id='' src={tag.src} />}
                        <Script strategy='afterInteractive' id={`id-${index}`}>
                            {tag?.function}
                        </Script>
                    </>
                </span>
            )}

            {/*
            <Script id="GTM" dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', process.env.GTM_ID);`}} />
            <Script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GTAG_ID}`} />
            <Script id="gtag-p" dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){window.dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', process.env.GTAG_ID);`
            }}/>
            */}
            {/* {env === 'production' &&
                <Script rel="preconnect" strategy="afterInteractive" type="text/javascript" id="viral-loops-loader" data-campaign-id={process.env.VIRAL_LOOPS_ID_CAMPAIGN} src="https://app.viral-loops.com/widgetsV2/core/loader.js"/>
            } */}
            <Script src="/modernizer.custom.js" />
            <PromotionContext.Provider value={{ promo: promoState, setPromo: setPromoState, dateActive: dateActiveState, details: detailsState, loading, setLoading }}>
                <Component {...pageProps} />
            </PromotionContext.Provider>

        </>
    )
}
export default App